<template>
  <!-- sms:check-in:sms-activated START -->
  <div class="sms-check-in" v-if="box && box.hasSmsCheckin === 1">

    <!-- sms:check-in:headline:description START -->
    <div class="headline-description">
      <h1 v-html="$t('outdoor.index.sms.headline')"></h1>
      <div
        class="description"
        v-html="$t('outdoor.index.sms.description')"
      ></div>
    </div>
    <!-- sms:check-in:headline:description END -->

    <div class="divider"></div>

    <!-- sms:check-in:form:sent START -->
    <div
      v-if="loginFormSent"
      class="headline-description form-sent"
    >
      <div
        class="description"
        v-html="this.loginFormSentMessage"
      ></div>
    </div>
    <!-- sms:check-in:form:sent END -->

    <!-- sms:check-in:form START -->
    <el-form
      status-icon
      :rules="rules"
      ref="loginForm"
      :model="loginForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="executeRecaptcha"
      v-if="!loginFormSent"
    >
      <!-- form:login:phone START -->
      <el-form-item prop="phone">
        <el-input
          inputmode="tel"
          :placeholder="`${$t('sms.form.phone')} *`"
          v-model="loginForm.phone"
          @keyup.enter.native="executeRecaptcha"
        ></el-input>
      </el-form-item>
      <!-- form:login:phone END -->

      <!-- form:login:agbs START -->
      <el-form-item prop="agbs">
        <el-checkbox v-model="loginForm.agbs">
          {{ `${$t('sms.form.agbs')}. *` }}
        </el-checkbox>
        <span class="agbs">
          (<strong><span
            v-on:click="dialogVisible = true"
            v-html="$t('sms.form.agbsdetails')"
          ></span></strong>)
        </span>
      </el-form-item>
      <!-- form:login:agbs END -->

      <!-- form:login:submit START -->
      <el-form-item>
        <el-button
          class="btn-default uppercase"
          type="primary"
          v-on:click="executeRecaptcha"
          v-if="!loadingVisible"
        >{{ $t('sms.form.send') }}</el-button>
      </el-form-item>
      <!-- form:login:submit END -->

      <!-- form:info START -->
      <p
        class="info"
        v-on:click="dialogInfoOpen"
        v-html="`${$t('sms.form.info')}`"
      ></p>
      <!-- form:info END -->

      <!-- form:google-recaptcha:submit START -->
      <recaptcha
        ref="recaptcha"
        v-on:verify="verifyForm"
      ></recaptcha>
      <!-- form:google-recaptcha:submit END -->

    </el-form>
    <!-- sms:check-in:form END -->

    <!-- sms:check-in:download-description START -->
    <div
      v-if="box.hasAppCheckin === 1"
      class="download-description app"
    >
      <h2
        class="app-headline"
        v-html="$t('outdoor.index.app.headline')"
      ></h2>
      <div
        class="app-description"
        v-html="$t('outdoor.index.app.description')"
        v-if="false"
      ></div>
    </div>
    <!-- sms:check-in:download-description END -->

    <!-- sms:check-in:download-buttons START -->
    <div
      v-if="box.hasAppCheckin === 1"
      class="download-buttons"
    >
      <div class="download-button download">
        <div class="inner">
          <router-link :to="'/apps'">
            <div class="button">
              <div class="icon"></div>
              <div class="content">
                <div class="text" v-html="$t('outdoor.index.app')"></div>
                <div class="text highlighted" v-html="$t('outdoor.index.appdownload')"></div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- sms:check-in:download-buttons END -->

    <!-- form:login:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:login:loading END -->

    <!-- form:login:info START -->
    <dialog-info
      :dialogVisible="dialogInfoVisible"
      :headline="dialogInfoHeadline"
      :excerpt="dialogInfoExcerpt"
      :description="dialogInfoDescription"
      :dialogClose="dialogInfoClose"
    />
    <!-- form:login:info END -->

    <!-- form:login:modal START -->
    <dialog-default
      :title="dialogTitle"
      :headline="dialogHeadline"
      :description="dialogDescription"
      :dialogVisible="dialogVisible"
      :dialogClose="dialogClose"
    />
    <!-- form:login:modal END -->
  </div>
  <!-- sms:check-in:sms-activated END -->

  <!-- sms:check-in:sms-not-activated START -->
  <div class="sms-check-in" v-else>
    <!-- sms:check-in:headline:description START -->
    <h1
      v-html="$t('sms.checkin.headline')"
      v-if="box && box.hasSmsCheckin === 0"
    ></h1>
    <div
      class="description"
      v-html="`${$t('sms.apps.redirect.first')} ${count} ${$t('sms.apps.redirect.second')}`"
      v-if="box && box.hasSmsCheckin === 0"
    ></div>
    <!-- sms:check-in:headline:description END -->
  </div>
  <!-- sms:check-in:sms-not-activated END -->
</template>

<script>
export default {
  name: 'sms-check-in',
  components: {
    DialogInfo: () => import('../components/shared/dialog/Info.vue'),
    DialogDefault: () => import('../components/shared/dialog/Default.vue'),
    LoadingDefault: () => import('../components/shared/loading/Default.vue'),
    Recaptcha: () => import('../components/shared/Recaptcha.vue'),
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      const phoneReg = /^(?:(?:00|\+)|0)[1-9]\d{1,2}?(?:[\d.\s\- /()]*)$/mg; // /^(?:(?:00|\+)|0)[1-9]\d{1,2}?(?:[\d.\s]*)$/mg; // eslint-disable-line
      if (!value) {
        return callback(new Error('Phone number cannot be empty'));
      }
      if (phoneReg.test(value)) {
        callback();
      } else {
        callback(new Error('The phone number format is incorrect'));
      }
      return value;
    };
    return {
      googleRecaptchaToken: '',
      /**
       * Box
       */
      boxID: this.$route.query ? this.$route.query.boxID : undefined,
      boxLang: this.$route.query ? this.$route.query.locale : undefined,
      box: null,
      /**
       * Redirect
       */
      count: 5,
      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('sms.loading.form.headline'),
      loadingExcerpt: this.$t('sms.loading.form.excerpt'),

      /**
       * Dialog (AGBs)
       */
      dialogVisible: false,
      dialogTitle: this.$t('sms.dialog.agbs.title'),
      dialogHeadline: this.$t('sms.dialog.agbs.headline'),
      dialogDescription: this.$t('sms.dialog.agbs.description'),

      /**
       * Dialog (Info)
       */
      dialogInfoVisible: false,
      dialogInfoHeadline: this.$t('sms.dialog.info.headline'),
      dialogInfoExcerpt: this.$t('sms.dialog.info.excerpt'),
      dialogInfoDescription: this.$t('sms.dialog.info.description'),

      /**
       * Form (Models) + Pass data to mixin
       */
      loginFormSent: false,
      loginForm: {
        // firstName: '',
        // lastName: '',
        phone: '',
        // email: '',
        agbs: [],
      },
      /**
       * Form (Rules - Validation)
       */
      rules: {
        phone: [
          {
            required: true,
            validator: checkPhone,
            message: ' ', // this.$t('sms.form.errorformfield')
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        agbs: [
          {
            type: 'array',
            required: true,
            message: ' ', // this.$t('sms.form.errorformfield')
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  async created() {
    if (this.boxID !== undefined) {
      this.box = await this.FETCH_BOX();
      // redirect if box id exists, but sms check in not
      if (this.box && this.box.hasSmsCheckin === 0) {
        console.log(this.box.hasAppCheckin);
        if (this.box.hasAppCheckin === 1) {
          this.redirectCountDown();
        } else {
          this.$router.push('/');
        }
      }
    } else {
      // redirect if box id undefined
      this.redirectCountDown();
    }
    // alert(JSON.stringify(this.$device));
  },
  methods: {
    async FETCH_BOX() {
      await this.$store.dispatch('setBoxSms', {
        payload: {
          id: this.boxID,
        },
      });
      return this.$store.getters.boxSms;
    },

    submitForm(formName) {
      const formData = new FormData();
      if (formName === 'loginForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;

            // append data to from
            formData.append('g-recaptcha-response', this.googleRecaptchaToken);
            formData.append('phoneNumber', this.loginForm.phone);
            if (this.boxLang !== undefined) {
              formData.append('locale', this.boxLang);
            } else {
              formData.append('locale', 'de_AT');
            }

            // Phone register
            this.$http.post(`${process.env.VUE_APP_BASE_URL}/registerPhoneNumber`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  setTimeout(() => {
                    this.loadingVisible = false;

                    this.success(this.$t('sms.checkin.success.qrcode'));

                    // this.success(this.$t('sms.checkin.success.headline'));
                    // setTimeout(() => {
                    //   this.success(this.$t('sms.checkin.success.qrcode'));
                    // }, 1500);

                    this.loginFormSent = true;
                    this.loginFormSentMessage = this.$t('sms.checkin.success.qrcode');

                    this.resetForm(formName);
                  }, 750);

                  // delete created notification dom element
                  setTimeout(() => {
                    if (document.querySelector('.el-notification')) {
                      const elem = document.querySelector('.el-notification');
                      elem.remove();
                    }
                  }, 7500);
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  this.errorStatus = error.response.data;
                  this.serverErrorMessages = this.errorStatus.errors;

                  console.clear();

                  setTimeout(() => {
                    this.loadingVisible = false;

                    this.serverErrorMessages.forEach((message) => {
                      this.error(`${this.$t(`error.${message.code}`)}`);
                    });

                    // this.resetForm(formName);
                  }, 750);
                }
              });
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
      }
    },

    dialogClose() {
      this.dialogVisible = false;
    },

    dialogInfoOpen() {
      this.dialogInfoVisible = true;
    },

    dialogInfoClose() {
      this.dialogInfoVisible = false;
    },

    redirectCountDown() {
      const timeInterval = setInterval(() => {
        this.count -= 1;
        if (this.count <= 0) {
          clearInterval(timeInterval);
          if (this.$device.ios) {
            window.location = 'https://apps.apple.com/at/app/google-maps-transit-essen/id585027354';
          } else {
            window.location = 'https://play.google.com/store/apps/details?id=com.google.android.apps.maps';
          }
        }
      }, 1000);
      // window.location.href = redirect;
    },

    success(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'success',
        position: 'bottom-right',
        showClose: false,
        duration: 6000,
      });
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
        showClose: false,
        duration: 6000,
      });
    },

    verifyForm(googleRecaptchaResponse) {
      if (googleRecaptchaResponse) {
        this.googleRecaptchaToken = googleRecaptchaResponse;
        this.submitForm('loginForm');
      }
    },

    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
  },
};
</script>
